import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import ReactAudioPlayer from "react-audio-player";
import Link from "gatsby-link";

const ProgrammPunkt24 = ({link, linktitle, title, time, mit, text, moderation, kooperation, zusatzinfo, url}) => {

    const breakpoint = useBreakpoint();

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const programmRef = useRef();

    return (
        <p className={"programm__entry"}>
            <p className="programm__time demibold" onClick={breakpoint.sm ? toggle : ""}>{time}</p>
            <p className="programm__title demibold" onClick={breakpoint.sm ? toggle : ""}>{title}</p>
            {url ? <div className="audiofile">
                <ReactAudioPlayer
                    src={url?.asset?.url}
                    controls
                />
            </div> : <></>}

            <div className={breakpoint.sm ? "collapsed" : ""} style={breakpoint.sm ? (open ? {height: programmRef.current.scrollHeight + "px"} : {height: "0px"} ) : {height:"unset"}} ref={programmRef}>
                <p className="programm__content">{mit ?
                    <span className="demibold">Mit: </span> : ""}{mit}</p>
                {text &&
                <p className="programm__content">{text}</p>}
                {moderation &&
                <p className={`programm__content`}><span className="demibold">Moderation: </span>{moderation}</p>}
                {kooperation &&
                    <p className={`programm__content`}><span className="demibold">In Kooperation mit: </span>{kooperation}</p>}
                <div className="programm__zusatztext">
                    <p className="small">{zusatzinfo}</p>
                </div>
                {link ? <div className="">
                    <Link to={link} className="btn__inner light icon--small">
                        {linktitle}
                    </Link>
                </div> : <></>}
            </div>
            {breakpoint.sm ?
                <div className="btn__outer flex">
                    <div className="btn__inner light flexend social morebtn" onClick={toggle}>
                        <svg className={open ? "rotated" : ""} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 18.347 18.346">
                            <g className={"svg-path-dark svg-path"} id="Group_169" data-name="Group 169" transform="translate(-1755.807 -3662.46)">
                                <path id="Path_274" data-name="Path 274" d="M135.631,11.23l10.144,10.145" transform="translate(1677.016 3560.613) rotate(45)"/>
                                <path id="Path_275" data-name="Path 275" d="M0,0,10.145,10.144" transform="translate(1772.154 3671.633) rotate(135)"/>
                            </g>
                        </svg>
                    </div>
                </div>
                : <></>}
        </p>
    )
}

export default ProgrammPunkt24;
